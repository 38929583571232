.app {
  height: 100vh;
  max-width: 568px;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--background-grey);
  font-family: 'Roboto', 'Arial', sans-serif;
}

.app__main {
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}
